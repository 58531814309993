import axios from 'axios'
// import { message } from '@/utils/index';


const http = axios.create({
  timeout: 1000 * 120,
  withCredentials: true,
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  config.headers.CQUSE = window.localStorage.getItem('CQ_USE')
  // config.headers.CQUSE='cheqi001'
  return config;
}, error => {
  return Promise.reject(error)
})


/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.status == 200) {
    return response.data
    // if (response.data != undefined && response.data.code != undefined && response.data.code == 0) { // 请求成功
    //   return response.data
    // } else if (response.data != undefined && response.data.code != undefined) { // 提示数据
    //   // message({
    //   //   type: "warning",
    //   //   message: response.data.respMsg,
    //   //   duration: 3000
    //   // })
    //   return response.data
    // }
  }
}, error => {
  // message({
  //   type: "error",
  //   message:  error.message,
  //   duration: 1000
  // })
  return Promise.reject(error)
})
/**
 * get请求
 */
http.get = (url, data = {}) => {
  return http({
    url: url,
    cache: false,
    method: "get",
    params: data
  })
}
export default http