import VueRouter from 'vue-router'
import Vue from 'vue'
//防止路由跳转push报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

let routes = [{
        path: `*`,
        name: `PageOne`,
        meta: {
            keepAlive: false
        },
        redirect: '/zngcfbdt'
    },
    // {
    //     path: `/index`,
    //     name:'index',
    //     meta: {
    //         keepAlive: false
    //     },
    //     component: resolve => require(['@/components/FirstPage.vue'], resolve),
    // },
    {
        path: `/zcbj`,
        name:'zcbj',
        meta: {
            keepAlive: false
        },
        component: resolve => require(['@/components/PolicyBackground.vue'], resolve),
    },
    {
        path: `/qindan`,
        name:'qindan',
        meta: {
            keepAlive: false
        },
        component: resolve => require(['@/components/Qindan.vue'], resolve),
    },
    {
        path: `/zngcfbdt`,
        name:'zngcfbdt',
        meta: {
            keepAlive: false
        },
        component: resolve => require(['@/components/FactoryDistribution.vue'], resolve),
    },
    {
        path: `/zngcsbrk`,
        name:'zngcsbrk',
        meta: {
            keepAlive: false
        },
        component: resolve => require(['@/components/DeclarationEntrance.vue'], resolve),
    },
    {
        path: `/zngcalj`,
        name:'zngcalj',
        meta: {
            keepAlive: false
        },
        component: resolve => require(['@/components/CaseSet.vue'], resolve),
    },
    {
        path: `/zngczjzk`,
        name:'zngczjzk',
        meta: {
            keepAlive: false
        },
        component: resolve => require(['@/components/ExpertThinkTank.vue'], resolve),
    },
    {
        path: `/gyly`,
        name:'gyly',
        meta: {
            keepAlive: false
        },
        component: resolve => require(['@/components/Lvyou.vue'], resolve),
    },
]
export default new VueRouter({
    mode: 'hash',
    // mode: 'history',
    // base: '/data-statistics-platform',
    routes: routes
})
Vue.use(VueRouter)