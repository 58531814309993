<template>
  <div class="index">
    <div class="title-name">{{ titleName }}</div>
    <div class="left-nav">
      <ul class="nav-ul">
        <li
          :class="checkedNav == item.path ? 'nav-li li-checked' : 'nav-li'"
          v-for="(item, i) in navData"
          :key="i"
        >
          <div v-if="item.path.startsWith('http')" class="li-inside-div">
            <a :href="item.path" target="_blank">{{ item.name }}</a>
            <div class="nav-triangle"></div>
          </div>
          <div v-else class="li-inside-div" @click="chooseOne(item)">
            {{ item.name }}
            <div class="nav-triangle"></div>
          </div>
        </li>
      </ul>
    </div>
    <div class="right-route">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      titleName: "智能工厂产业地图",
      checkedNav: "",
      navData: [
        // { name: "首页", path: "/index" },
        // { name: "政策背景", path: "/zcbj" },
        // { name: "上海市智能工厂名单", path: "/qindan" },
        { name: "智能工厂分布地图", path: "/zngcfbdt" },
        {
          name: "成熟度能力评估",
          path: "/zngcsbrk",
        },
        { name: "智能工厂案例集", path: "/zngcalj" },
        { name: "智能工厂路线图", path: "/gyly" },
        // {name:'智能工厂专家智库',path:'/zngczjzk'}
      ],
    };
  },
  watch: {
    $route: {
      //监听的对象
      deep: true, //深度监听设置为 true
      handler: function () {
        this.getRouter();
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    //拿取路由地址匹配选中导航
    getRouter() {
      this.navData.map((x) => {
        if (x.path.indexOf(this.$route.path) > -1 && this.$route.path != "/") {
          this.checkedNav = x.path;
        }
      });
    },
    chooseOne(item) {
      this.checkedNav = item.path;
      this.$router.push(item.path);
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 1920px;
  height: 1080px;
  background: url("../assets/imgs/bg.png") no-repeat;
  position: relative;
  @flex_row();
  align-items: flex-start;
  .title-name {
    width: 100%;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 15px;
    text-align: center;
    font-size: 42px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #00feef;
  }
  .left-nav {
    width: 315px;
    margin-left: 90px;
    margin-top: 135px;
    .nav-ul {
      width: 255px;
      .nav-li {
        display: inline-block;
        width: 100%;
        height: 82px;
        line-height: 82px;
        margin-bottom: 26px;
        border: 2px solid #1af7ff;
        .li-inside-div,.li-inside-div a {
          width: calc(100% + 1px);
          height: 100%;
          text-decoration: none;
          position: relative;
          text-align: center;
          font-size: 22px;
          font-family: PingFangHK-Medium, PingFangHK;
          font-weight: 500;
          color: #1af7ff;
          cursor: pointer;
          .nav-triangle {
            position: absolute;
            right: 6px;
            bottom: 6px;
            width: 0;
            height: 0;
            border-bottom: 23px solid #1af7ff;
            border-left: 23px solid transparent;
          }
        }
      }
      .li-checked {
        box-shadow: 0px 0px 30px 0px #1af7ff, 0px 5px 5px 0px #00eaff;
        .li-inside-div {
          box-shadow: 0px 5px 5px 0px #1af7ff inset;
        }
      }
    }
  }
  // .right-route{
  //   width: 1375px;
  // }
}
</style>
