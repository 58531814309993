<template>
  <div id="app">
    <index/>
  </div>
</template>

<script>
import index from '@/views/index.vue'

export default {
  name: 'App',
  components: {
    index
  }
}
</script>

<style>
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* width: 1920px;
  height: 1080px; */
  /* margin-top: 60px; */
}
body {
  margin: 0;
}
p{
  margin-block-start: 0;
  margin-block-end: 0;
}
ul {list-style:none;margin:0px;padding: 0;} 
</style>
