import Vue from 'vue'
import App from './App.vue'
import less from 'less'
import router from './router'
import Element from 'element-ui'
import httpRequest from '@/utils/httpInterceptors'
import api from '@/utils/api'

// 引入echarts
import * as echarts from 'echarts'
import 'echarts-gl'

import '@/components/svgIcon'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.use(less)
Vue.use(Element)

Vue.prototype.$echarts = echarts
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.$api = api // 接口

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
